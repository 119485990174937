import snakeCase from 'lodash/snakeCase';

import { TEMPO } from 'lib/constants';
import { objectToQueryString } from 'services/RequestService/helpers';

// NON-REACT PATHS
export const SIGN_IN_PATH = '/users/sign_in';
export const OUR_MENU_PATH = '/our-menu';

// COOKBOOK
export const COOKBOOK_PATH = '/account/cookbook';
export const COOKBOOK_SHOPPING_LIST_PATH = `${COOKBOOK_PATH}/shopping_list`;

export const cookbookMealDetailsPath = mealId => `${COOKBOOK_PATH}?mealId=${mealId}`;

// PICK YOUR MEALS
export const PICK_MEALS_MENU_PATH = '/pick-meals';
export const PICK_MEALS_BILLING_PATH = '/pick-meals/billing';
export const PICK_MEALS_THANK_YOU_PATH = '/pick-meals/thankyou';

// DASHBOARD
const DASHBOARD_ROOT_PATH = '/account/calendar';

export const ROOT_PATH_NAME = 'ROOT_PATH';
export const MENU_PATH_NAME = 'MENU_PATH';
export const SUMMARY_PATH_NAME = 'SUMMARY_PATH';
export const MEAL_DETAILS_PATH_NAME = 'MEAL_DETAILS_PATH';

export const dashboardRootPath = (params = null) =>
  `${DASHBOARD_ROOT_PATH}${params ? `?${objectToQueryString(params)}` : ''}`;

export const dashboardMenuPath = (menuSlug, params = null) =>
  `${DASHBOARD_ROOT_PATH}/${menuSlug}${params ? `?${objectToQueryString(params)}` : ''}`;

export const dashboardSummaryPath = menuSlug => `${DASHBOARD_ROOT_PATH}/${menuSlug}/summary`;

// Path Matching Helpers
export const isDashboardPath = (pathname, params) => pathname === dashboardRootPath(params);

export const isMenuPath = (pathname, menuSlug, params) =>
  pathname === dashboardMenuPath(menuSlug, params);

export const isSummaryPath = (pathname, menuSlug) => pathname === dashboardSummaryPath(menuSlug);

export const isMealDetailsPath = (pathname, { search, menuSlug, mealId, title }) => {
  const fullPath = `${pathname}${search ? search : ''}`;
  return (
    fullPath === dashboardRootPath({ mealId, menuSlug, title }) ||
    fullPath === dashboardMenuPath(menuSlug, { mealId, title })
  );
};

// ACCOUNT
const ACCOUNT_SETTINGS_PATH = '/account/settings';
export const ACCOUNT_ORDERS_PATH = '/account/order-history';
export const ACCOUNT_ORDERS_SUMMARY_PATH = '/account/order-history/:menuId';
export const ACCOUNT_SETTINGS_PAUSE_REASONS_PATH = `${ACCOUNT_SETTINGS_PATH}/pause-reasons`;
export const ACCOUNT_SETTINGS_PAUSES_PATH = `${ACCOUNT_SETTINGS_PATH}/pause`;
export const ACCOUNT_EDIT_PATH = `${ACCOUNT_SETTINGS_PATH}/edit`;
export const ACCOUNT_SUBSCRIPTION_PATH = `${ACCOUNT_SETTINGS_PATH}/subscriptions`;
export const ACCOUNT_REACTIVATIONS_PATH = `${ACCOUNT_SETTINGS_PATH}/reactive`;
export const ACCOUNT_PREFERENCES_PATH = `${ACCOUNT_SETTINGS_PATH}/taste-profile`;
export const ACCOUNT_PAYMENT_PATH = `${ACCOUNT_SETTINGS_PATH}/billing`;
export const ACCOUNT_SCHEDULE_RESTART = '/account/pause/scheduled-restart/new';
export const ACCOUNT_NOTIFICATIONS_PATH = `${ACCOUNT_SETTINGS_PATH}/notifications`;
export const ACCOUNT_CCPA_REQUEST_PATH = `${ACCOUNT_SETTINGS_PATH}/privacy-request`;
export const ACCOUNT_RESTART_PATH = `${ACCOUNT_SETTINGS_PATH}/reactivate`;
export const ACCOUNT_VERIFY_PATH = '/account/verify';
export const ACCOUNT_VERIFY_PAYMENT_PATH = `${ACCOUNT_VERIFY_PATH}/payment`;
export const ACCOUNT_VERIFY_ADDRESS_PATH = `${ACCOUNT_VERIFY_PATH}/address`;
export const ACCOUNT_REACTIVATE_PATH = '/account/reactivate';
export const ACCOUNT_REACTIVATE_OFFER_PATH = `${ACCOUNT_REACTIVATE_PATH}/offer`;
export const BOX_REACTIVATIONS_PATH = '/account/reactivate/box';
export const OFFER_REWARDS_PATH = '/account/rewards';
export const FAQ = '/faq';
export const SUPPORT_REQUEST = '/support-request';

export const TEMPO_SUPPORT_REQUEST = 'https://support.tempomeals.com/hc/en-us/requests/new';

export const railsPauseDeflectorRoute = reason =>
  `/account/pause/reason/new?reason=${snakeCase(reason)}`;

export const pauseSuccessPath = frequencySubscriptionChangeId =>
  `/account/pause/reason/${frequencySubscriptionChangeId}`;

// RECIPE TESTING SOCIETY
const RECIPE_TESTING_SOCIETY_PATH = '/account/recipe-testing-society';

export const dashboardRecipeTestingSocietyPath = (params = null) =>
  `${RECIPE_TESTING_SOCIETY_PATH}${params ? `?${objectToQueryString(params)}` : ''}`;

// SEND A BOX
const SEND_A_BOX_ROOT_PATH = '/send-a-box';
const SEND_A_BOX_RECIPIENT_PATH = `${SEND_A_BOX_ROOT_PATH}/recipients`;

const GIFT_CARD_PATH = '/gift-cards';

export const sendABoxRootPath = (params = null) =>
  `${SEND_A_BOX_ROOT_PATH}${params ? `?${objectToQueryString(params)}` : ''}`;

export const sendABoxGifterEditPath = boxHashId => `${SEND_A_BOX_ROOT_PATH}/${boxHashId}/edit`;
export const sendABoxCheckoutPath = boxHashId =>
  `${SEND_A_BOX_ROOT_PATH}/${boxHashId}/order-summary`;
export const sendABoxCheckoutConfirmationPath = boxHashId =>
  `${SEND_A_BOX_ROOT_PATH}/${boxHashId}/confirmation`;

export const sendABoxGiftCardPath = () => GIFT_CARD_PATH;

export const sendABoxRecipientBoxViewPath = boxHashId =>
  `${SEND_A_BOX_RECIPIENT_PATH}/${boxHashId}/view`;
export const sendABoxRecipientBoxEditPath = (boxHashId, token) =>
  `${SEND_A_BOX_RECIPIENT_PATH}/${boxHashId}/edit${token ? `?${objectToQueryString({ token })}` : ''}`;
export const sendABoxRecipientBoxConfirmationPath = (boxHashId, token) =>
  `${SEND_A_BOX_RECIPIENT_PATH}/${boxHashId}/confirmation${token ? `?${objectToQueryString({ token })}` : ''}`;

//Funnel V2
const INTRO_PATH = '/signup/intro';
const PICK_PLAN_PATH = '/signup/plans';
const PROMO_PATH = '/signup/promo';
const BOX_PATH = '/signup/box';
const CHECKOUT_PATH = '/signup/billing';
const THANK_YOU_PATH = '/signup/thankyou';

//Tempo Box Recommender
export const BOX_RECOMMENDER_PEOPLE = '/signup/box-people';
export const BOX_RECOMMENDER_MEALS = '/signup/box-meals';
export const BOX_RECOMMENDER_DAYS = '/signup/box-days';

export const TEMPO_FUNNEL_REACTIVATE_PATH = '/signup/reactivate/tempo';

// New Preferences Pages
export const PREFERENCE_DIETARY = '/signup/preference/dietary';
export const PREFERENCE_AVOID = '/signup/preference/avoid';

export const getBrandRoute = ({ brand, isFamily = false, addSuffix = true }) => {
  let routeSuffix = '';

  if (addSuffix) {
    if (isFamily && brand !== TEMPO) {
      routeSuffix = '/family';
    } else {
      routeSuffix = brand === TEMPO ? '/tempo' : '/standard';
    }
  }

  return {
    intro: `${INTRO_PATH}${routeSuffix}`,
    plan: `${PICK_PLAN_PATH}${routeSuffix}`,
    preferenceDietary: `${PREFERENCE_DIETARY}${routeSuffix}`,
    preferenceAvoid: `${PREFERENCE_AVOID}${routeSuffix}`,
    promo: `${PROMO_PATH}${routeSuffix}`,
    box: `${BOX_PATH}${routeSuffix}`,
    boxRecommenderPeople: `${BOX_RECOMMENDER_PEOPLE}${routeSuffix}`,
    boxRecommenderMeals: `${BOX_RECOMMENDER_MEALS}${routeSuffix}`,
    boxRecommenderDays: `${BOX_RECOMMENDER_DAYS}${routeSuffix}`,
    billing: `${CHECKOUT_PATH}${routeSuffix}`,
    thankyou: `${THANK_YOU_PATH}${routeSuffix}`,
  };
};

const getTempoSteps = brandRoutes => {
  return {
    [brandRoutes.preferenceDietary]: 2,
    [brandRoutes.preferenceAvoid]: 3,
    [brandRoutes.promo]: 4,
    [brandRoutes.boxRecommenderPeople]: 5,
    [brandRoutes.boxRecommenderMeals]: 6,
    [brandRoutes.boxRecommenderDays]: 7,
    [brandRoutes.box]: 8,
    [brandRoutes.billing]: 9,
    [brandRoutes.thankyou]: 10,
  };
};
const getHomeChefSteps = (brandRoutes, isPickYourPlan) => {
  return isPickYourPlan
    ? {
        [brandRoutes.plan]: 2,
        [brandRoutes.preferenceDietary]: 3,
        [brandRoutes.preferenceAvoid]: 4,
        [brandRoutes.promo]: 5,
        [brandRoutes.box]: 6,
        [brandRoutes.billing]: 7,
        [brandRoutes.thankyou]: 8,
      }
    : {
        [brandRoutes.preferenceDietary]: 2,
        [brandRoutes.preferenceAvoid]: 3,
        [brandRoutes.promo]: 4,
        [brandRoutes.box]: 5,
        [brandRoutes.billing]: 6,
        [brandRoutes.thankyou]: 7,
      };
};
export const funnelSteps = ({ brand, isFamily = false, isPickYourPlan = false }) => {
  const brandRoutes = getBrandRoute({ brand, isFamily });
  return {
    [brandRoutes.intro]: 1,
    ...(brand === TEMPO
      ? getTempoSteps(brandRoutes)
      : getHomeChefSteps(brandRoutes, isPickYourPlan)),
  };
};

export function getRoutesMap(brand, isFamily) {
  const brandRoutes = getBrandRoute({ brand: brand, isFamily: isFamily });

  return {
    on_pick_your_plan_step: brandRoutes.plan,
    on_preference_dietary_step: brandRoutes.preferenceDietary,
    on_preference_avoid_step: brandRoutes.preferenceAvoid,
    on_promo_step: brandRoutes.promo,
    on_build_a_box_step: brandRoutes.box,
    on_box_recommender_people_step: brandRoutes.boxRecommenderPeople,
    on_box_recommender_meals_step: brandRoutes.boxRecommenderMeals,
    on_box_recommender_days_step: brandRoutes.boxRecommenderDays,
    on_billing_step: brandRoutes.billing,
    on_thankyou_step: brandRoutes.thankyou,
  };
}
