export const SVS_CODE_LENGTH = 19;
export const SVS_CODE_INDICATOR = '600649';
export const CTA_CONT_COPY = 'Continue';
export const FUEL_VOUCHER_CODES = {
  FUEL_1: 'FUEL1',
  FUEL_2: 'FUEL2',
};
export const FAMILY_MEAL_PLAN_SLUG = 'family';
export const HOME_CHEF_MEALS_PER_WEEK = [2, 3, 4, 5, 6];
export const STANDARD_SERVINGS_PER_MEAL = [2, 4, 6];
export const FAMILY_SERVINGS_PER_MEAL = [4, 8];
export const TEMPO_PLAN_MEALS_PER_WEEK = [6, 8, 10, 12, 14, 16];
export const REACTIVATION_TEMPO_PLAN_MEALS_PER_WEEK = [6, 8, 10, 12, 14, 16, 20];
export const AMEXBCE_PROMO_CODE = 'AMEXBCE15';
export const SHOW_PROMO_BANNER_LOCAL_STORAGE = 'showPromoBanner';
export const SKINNYTASTE = 'skinnytaste';
export const DELISH = 'delish';
export const AMBITIOUS_KITCHEN = 'ambitious-kitchen';
export const BLUEY = 'bluey';
export const HALF_BAKED = 'half-baked-harvest';
export const PAW_PATROL = 'paw-patrol';
export const PAW_PATROL_PARAM = 'pp';
export const GOOD_LOOKIN_COOKIN = 'good-lookin-cookin';
export const GOOD_LOOKIN_COOKIN_PARAM = 'glc';
export const KIDS_EAT_IN_COLOR_PARAM = 'kids';
export const KIDS_EAT_IN_COLOR = 'kids-eat-in-color';
export const FAMILY_MEALS = 'familyMeals';

export const INTRO_SMS_OPT_IN = 'introSmsOptIn';
export const FAMILY_INTRO_PATH = '/signup/intro/family';
export const FAMILY_INTRO_PATH_WITH_PARAM = '/signup/intro/family?family=true';
export const TEMPO_INTRO_PATH = '/signup/intro/tempo';
export const CORE_INTRO_PATH = '/signup/intro/standard';

export const Statuses = {
  PICK_PLAN_STEP: 'on_pick_your_plan_step',
  DIETARY_PREFERENCES_STEP: 'on_preference_dietary_step',
  DIETARY_AVOID_STEP: 'on_preference_avoid_step',
  PROMO_STEP: 'on_promo_step',
  BOX_PEOPLE_STEP: 'on_box_people_step',
  BOX_STEP: 'on_build_a_box_step',
  BILLING_STEP: 'on_billing_step',
  THANK_YOU_STEP: 'on_thankyou_step',
  FUNNEL_COMPLETE: 'funnel_complete',
};

export const SKIP_PATHS = {
  INTRO: 'toIntro',
  PICK_PLAN: 'toPickPlan',
  PREF_DIETARY: 'toPreferencesDietary',
  PROMO: 'toPromo',
  BOX_PEOPLE: 'toBoxPeople',
  BOX: 'toBox',
  BILLING: 'toBilling',
  THANK_YOU: 'toThankYou',
};

export const PARTNERSHIP_RESOURCE_TYPES = {
  LOGO: 'logo',
  MEAL_IMAGE: 'meal_image',
  BADGE: 'badge',
};

export const PARTNERSHIP_PARAM_MAP = {
  skt: 'skinnytaste',
  dlsh: 'delish',
  ambkit: 'ambitious-kitchen',
  bluey: 'bluey',
  hbh: 'half-baked-harvest',
  pp: 'paw-patrol',
  glc: 'good-lookin-cookin',
  kids: 'kids-eat-in-color',
  star: 'starwars',
};
