export const SHOW_FEATURE_FLAG_CONSOLE = 'showFeatureFlagConsole';
export const SHOPPING_LIST = 'shoppingList';
export const SEND_A_BOX = 'sendABox';
export const SHOW_MAINTENANCE_BANNER = 'showMaintenanceBanner';
export const TEMPO_FUNNEL_REACTIVATION = 'tempoFunnelReactivation';
export const REACTIVATION_VERIFICATION = 'reactivationVerification';
export const ENABLE_INTERNAL_SKIP_FUNNEL = 'enableInternalSkipFunnel';
export const REACTIVATIONS_2 = 'reactivations2';
export const SHOW_PAW_PATROL = 'showPawPatrol';
export const ENABLE_BASKET_REDESIGN = 'enableBasketRedesign';
