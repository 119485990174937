export const PICK_YOUR_PLAN_FUNNEL_EXPERIMENT = 'pick_your_plan_funnel';
export const TEMPO_THUMB_REVIEWS = 'tempo_thumb_reviews';
export const REFERRAL_CREDIT_EXPERIMENT = 'q3_24_referral_credit';

export const SMS_FUNNEL_INCENTIVE_EXPERIMENT = 'sms_funnel_incentive';

export const SMS_INCENTIVE_PROMO_DISCLAIMER_TEXT =
  'Valid only for new SMS customers. Get an additional 5% off your first box. Offer expires 10 weeks after sign up. Limit 1 per household. No cash value. Not valid in all states. Void where prohibited.';

export const SMS_DISCOUNT_TAG_TEXT = 'GET AN EXTRA 5% OFF';
